import React from 'react';

// core components
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";
import Skeleton from '@material-ui/lab/Skeleton';
import Button from '@material-ui/core/Button'
import { Link as RouterLink } from "react-router-dom";


import carSeats from '../../../assets/images/car-seats-red.svg';
import carPS from '../../../assets/images/car-ps-red.svg';
import carFuel from '../../../assets/images/car-fuel-red.svg';
import carShift from '../../../assets/images/car-shift-red.svg';

import timeHalfDay from '../../../assets/images/time-halfday.svg';
import timeDay from '../../../assets/images/time-day.svg';
import timeWeek from '../../../assets/images/time-week.svg';
import timeMonth from '../../../assets/images/time-month.svg';

import truckInnerHeight from '../../../assets/images/truck-inner-height-red.svg';
import truckInnerWidth from '../../../assets/images/truck-inner-width-red.svg';
import truckInnerLength from '../../../assets/images/truck-inner-length-red.svg';
import truckHeight from '../../../assets/images/truck-height-red.svg';

export default function PostSection(props) {

  return (
    <>
      <div className="grid-section">
        <GridContainer justify="center">

          { props.loadingCar ?

              <GridItem xs={12} sm={10} md={8}>

                <img className="display-car-image" src={props.car.carImage.image['full']} alt={(props.car.make + ' ' + props.car.model)}/>

                <div className="display-car-specifications">
                { (typeof(props.car) === 'object') ?
                  <>
                  {
                    (props.car.type == 1) ?
                      <>
                        <div className="display-car-specifications-title">Preise</div>
                        <GridContainer justify="center" style={{marginBottom:'50px'}} spacing={2}>
                          <GridItem style={{width:'360px', margin:'10px', height:'80px', padding:'0px'}} xs={12} sm={4} md={4}>
                            <img className="display-car-specifications-specbox-image" src={timeHalfDay}></img>
                            <div className="display-car-specifications-specbox-title">Halbtag</div>
                            <div className="display-car-specifications-specbox-value">{props.car.halfDayPrice} CHF</div>
                          </GridItem>
                          <GridItem style={{width:'360px', margin:'10px', height:'80px', padding:'0px'}} xs={12} sm={4} md={4}>
                            <img className="display-car-specifications-specbox-image" src={timeDay}></img>
                            <div className="display-car-specifications-specbox-title">Tag</div>
                            <div className="display-car-specifications-specbox-value">{props.car.dayPrice} CHF</div>
                          </GridItem>
                          <GridItem style={{width:'360px', margin:'10px', height:'80px', padding:'0px'}} xs={12} sm={4} md={4}>
                            <img className="display-car-specifications-specbox-image" src={timeWeek}></img>
                            <div className="display-car-specifications-specbox-title">Woche</div>
                            <div className="display-car-specifications-specbox-value">{props.car.weekPrice} CHF</div>
                          </GridItem>
                          <GridItem style={{width:'360px', margin:'10px', height:'80px', padding:'0px'}} xs={12} sm={4} md={4}>
                            <img className="display-car-specifications-specbox-image" src={timeMonth}></img>
                            <div className="display-car-specifications-specbox-title">Monat</div>
                            <div className="display-car-specifications-specbox-value">{props.car.monthPrice} CHF</div>
                          </GridItem>
                        </GridContainer>
                        <div  className="display-car-specifications-title">Spezifikationen</div>
                        <GridContainer justify="center" spacing={2}>
                          <GridItem style={{width:'360px', margin:'10px', height:'80px', padding:'0px'}} xs={12} sm={4} md={4}>
                            <img className="display-car-specifications-specbox-image" src={carSeats}></img>
                            <div className="display-car-specifications-specbox-title">Plätze</div>
                            <div className="display-car-specifications-specbox-value">{props.car.seatsAll}</div>
                          </GridItem>
                          <GridItem style={{width:'360px', margin:'10px', height:'80px', padding:'0px'}} xs={12} sm={4} md={4}>
                            <img className="display-car-specifications-specbox-image" src={carPS}></img>
                            <div className="display-car-specifications-specbox-title">Leistung</div>
                            <div className="display-car-specifications-specbox-value">{props.car.performance} PS</div>
                          </GridItem>
                          <GridItem style={{width:'360px', margin:'10px', height:'80px', padding:'0px'}} xs={12} sm={4} md={4}>
                            <img className="display-car-specifications-specbox-image" src={carFuel}></img>
                            <div className="display-car-specifications-specbox-title">Treibstoff</div>
                            <div className="display-car-specifications-specbox-value">{
                              {
                                1: <>Diesel</>,
                                2: <>Benzin</>,
                                3: <>Elektro</>,
                              }[props.car.carFuel]
                            }</div>
                          </GridItem>
                          <GridItem style={{width:'360px', margin:'10px', height:'80px', padding:'0px'}} xs={12} sm={4} md={4}>
                            <img className="display-car-specifications-specbox-image" src={carShift}></img>
                            <div className="display-car-specifications-specbox-title">Schaltung</div>
                            <div className="display-car-specifications-specbox-value">{
                              {
                                1: <>Automat</>,
                                2: <>Geschalten</>,
                              }[props.car.driveType]
                            }</div>
                          </GridItem>
                        </GridContainer>
                      </>
                      :
                      <>
                        <div className="display-car-specifications-title">Preise</div>
                        <GridContainer justify="center" style={{marginBottom:'50px'}} spacing={2}>
                          <GridItem style={{width:'360px', margin:'10px', height:'80px', padding:'0px'}} xs={12} sm={4} md={4}>
                            <img className="display-car-specifications-specbox-image" src={timeHalfDay}></img>
                            <div className="display-car-specifications-specbox-title">Halbtag</div>
                            <div className="display-car-specifications-specbox-value">{props.car.halfDayPrice} CHF</div>
                          </GridItem>
                          <GridItem style={{width:'360px', margin:'10px', height:'80px', padding:'0px'}} xs={12} sm={4} md={4}>
                            <img className="display-car-specifications-specbox-image" src={timeDay}></img>
                            <div className="display-car-specifications-specbox-title">Tag</div>
                            <div className="display-car-specifications-specbox-value">{props.car.dayPrice} CHF</div>
                          </GridItem>
                          <GridItem style={{width:'360px', margin:'10px', height:'80px', padding:'0px'}} xs={12} sm={4} md={4}>
                            <img className="display-car-specifications-specbox-image" src={timeWeek}></img>
                            <div className="display-car-specifications-specbox-title">Woche</div>
                            <div className="display-car-specifications-specbox-value">{props.car.weekPrice} CHF</div>
                          </GridItem>
                          <GridItem style={{width:'360px', margin:'10px', height:'80px', padding:'0px'}} xs={12} sm={4} md={4}>
                            <img className="display-car-specifications-specbox-image" src={timeMonth}></img>
                            <div className="display-car-specifications-specbox-title">Monat</div>
                            <div className="display-car-specifications-specbox-value">{props.car.monthPrice} CHF</div>
                          </GridItem>
                        </GridContainer>
                        <div  className="display-car-specifications-title">Spezifikationen</div>
                        <GridContainer justify="center" spacing={2}>
                          <GridItem style={{width:'360px', margin:'10px', height:'80px', padding:'0px'}} xs={12} sm={4} md={4}>
                            <img className="display-car-specifications-specbox-image" src={truckHeight}></img>
                            <div className="display-car-specifications-specbox-title">Fahrzeug Höhe</div>
                            <div className="display-car-specifications-specbox-value">{props.car.carHeight} m</div>
                          </GridItem>
                          <GridItem style={{width:'360px', margin:'10px', height:'80px', padding:'0px'}} xs={12} sm={4} md={4}>
                            <img className="display-car-specifications-specbox-image" src={truckInnerHeight}></img>
                            <div className="display-car-specifications-specbox-title">Laderaum Höhe</div>
                            <div className="display-car-specifications-specbox-value">{props.car.loadRoomHeight} m</div>
                          </GridItem>
                          <GridItem style={{width:'360px', margin:'10px', height:'80px', padding:'0px'}} xs={12} sm={4} md={4}>
                            <img className="display-car-specifications-specbox-image" src={truckInnerWidth}></img>
                            <div className="display-car-specifications-specbox-title">Laderaum Breite</div>
                            <div className="display-car-specifications-specbox-value">{props.car.loadRoomWidth} m</div>
                          </GridItem>
                          <GridItem style={{width:'360px', margin:'10px', height:'80px', padding:'0px'}} xs={12} sm={4} md={4}>
                            <img className="display-car-specifications-specbox-image" src={truckInnerLength}></img>
                            <div className="display-car-specifications-specbox-title">Laderaum Länge</div>
                            <div className="display-car-specifications-specbox-value">{props.car.loadRoomLength} m</div>
                          </GridItem>
                          <GridItem style={{width:'360px', margin:'10px', height:'80px', padding:'0px'}} xs={12} sm={4} md={4}>
                            <img className="display-car-specifications-specbox-image" src={carPS}></img>
                            <div className="display-car-specifications-specbox-title">Leistung</div>
                            <div className="display-car-specifications-specbox-value">{props.car.performance} PS</div>
                          </GridItem>
                          <GridItem style={{width:'360px', margin:'10px', height:'80px', padding:'0px'}} xs={12} sm={4} md={4}>
                            <img className="display-car-specifications-specbox-image" src={carFuel}></img>
                            <div className="display-car-specifications-specbox-title">Treibstoff</div>
                            <div className="display-car-specifications-specbox-value">{
                              {
                                1: <>Diesel</>,
                                2: <>Benzin</>,
                                3: <>Elektro</>,
                              }[props.car.carFuel]
                            }</div>
                          </GridItem>
                          <GridItem style={{width:'360px', margin:'10px', height:'80px', padding:'0px'}} xs={12} sm={4} md={4}>
                            <img className="display-car-specifications-specbox-image" src={carShift}></img>
                            <div className="display-car-specifications-specbox-title">Schaltung</div>
                            <div className="display-car-specifications-specbox-value">{
                              {
                                1: <>Automat</>,
                                2: <>Geschalten</>,
                              }[props.car.driveType]
                            }</div>
                          </GridItem>
                        </GridContainer>
                      </>
                  }
                  </>
                  :
                  <>
                  </>
                }
                </div>
              </GridItem>
            :
            <>
              <GridItem xs={12} sm={10} md={8}>
                <div className="post-area">
                  <div className="post-area-image-wrapper">
                    <Skeleton variant="rect" style={{ marginTop: '50px' }} width={'100%'}  height={'100px'} />
                  </div>
                  <div className="post-area-description-wrapper">
                    <h4 className="post-area-title"><Skeleton variant="rect" width={'100%'}  height={'600px'}/></h4>
                  </div>
                </div>
              </GridItem>
            </>
          }
        </GridContainer>
        <GridContainer justify="center">
        </GridContainer>
      </div>
    </>
  );
}
