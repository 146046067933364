import './assets/css/main.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import React, { Fragment, useEffect, useState } from "react";
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';

import HomePage from './views/HomePage/HomePage';
import CarsOnePage from './views/CarsOnePage/CarsOnePage';
import ImpressumPage from './views/ImpressumPage/ImpressumPage';
import DataProtectionPage from './views/DataProtectionPage/DataProtectionPage';
import CookieConsent from "react-cookie-consent";
import { AnimatePresence, motion} from "framer-motion";
import { ParallaxProvider } from 'react-scroll-parallax';
import API from './api/api';
import Dialog from '@material-ui/core/Dialog';
import useAckee from 'use-ackee'

const garageApi = new API();

function App() {

  const location = useLocation();

	/*useAckee(location.pathname, {
		server: 'https://analytics.grischa.io',
		domainId: 'f5a4740f-4452-42c9-a3a5-bb339f406998'
	}, {
		ignoreLocalhost: false,
    detailed: true,
	})*/

  /*(function(d,t) {
      var BASE_URL="https://support.stiffler-fahrzeugvermietung.ch";
      var g=d.createElement(t),s=d.getElementsByTagName(t)[0];
      g.src=BASE_URL+"/packs/js/sdk.js";
      g.defer = true;
      g.async = true;
      s.parentNode.insertBefore(g,s);
      g.onload=function(){
        window.chatwootSDK.run({
          websiteToken: 'X3Hbs2dpz5FDVK5jiLvHpvom',
          baseUrl: BASE_URL
        })
      }
    })(document,"script");*/

  const [configuration, setConfiguration] = useState({});
  const [configurationload, setConfigurationLoad] = useState(false);
  const [errorDialog, setErrorDialog] = useState(false);

  const products = {
    initial: { y: -100, opacity: 0 },
    animate: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.25,
        ease: [0.6, -0.05, 0.01, 0.99],
      },
    },
  };

  useEffect(() => {
    /*garageApi.openEndpoints.site.getAll()
      .then((getRequest) => {
        try
        {
          if(Object.keys(getRequest.data.results).length === 0)
          {
          } else {
            setConfiguration(getRequest.data.results[0]);
            setConfigurationLoad(true);
          }

        }
        catch(e)
        {
                //setErrorDialog(true);
          setConfigurationLoad(true);
        }
      });*/
      setConfigurationLoad(true);
  }, []);

  return (
    <ParallaxProvider>
      <Dialog fullScreen open={errorDialog}>
        <div className="error-text">
          Site Configuration Error
        </div>
      </Dialog>
      <CookieConsent
        location="bottom"
        buttonText="Akzeptieren"
        cookieName="acceptCookies"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        >
        Diese Website verwendet Cookies, um die Benutzererfahrung zu verbessern.
      </CookieConsent>
      <AnimatePresence exitBeforeEnter>
        <Switch location={location} key={location.pathname}>

          <PublicRoute exact path="/impressum">
            { configurationload ?
              <motion.section exit={{ opacity: 0 }}>
                <motion.div initial="initial" animate="animate" variants={products}>
                    <ImpressumPage configuration={configuration}/>
                </motion.div>
              </motion.section>
              :
              null
            }
          </PublicRoute>
          <PublicRoute exact path="/datenschutz">
            { configurationload ?
              <motion.section exit={{ opacity: 0 }}>
                <motion.div initial="initial" animate="animate" variants={products}>
                    <DataProtectionPage configuration={configuration}/>
                </motion.div>
              </motion.section>
              :
              null
            }
          </PublicRoute>
          <PublicRoute path="/fahrzeuge/:id">
            { configurationload ?
              <motion.section exit={{ opacity: 0 }}>
                <motion.div initial="initial" animate="animate" variants={products}>
                    <CarsOnePage configuration={configuration}/>
                </motion.div>
              </motion.section>
              :
              null
            }
          </PublicRoute>
          <PublicRoute exact path="/">
            { configurationload ?
              <motion.section exit={{ opacity: 0 }}>
                <motion.div initial="initial" animate="animate" variants={products}>
                    <HomePage configuration={configuration}/>
                </motion.div>
              </motion.section>
              :
              null
            }
          </PublicRoute>

          <ErrorRoute path="*"/>
        </Switch>
      </AnimatePresence>
    </ParallaxProvider>
  );
}


function PublicRoute({ children, ...rest })
{
  return (
    <Route {...rest} render={({ location }) =>
        (children)
      }
    />
  )
}

function ErrorRoute({ children, ...rest })
{
  return (
    <Route {...rest} render={({ location }) =>
        (<Redirect to="/" />)
      }
    />
  )
}

export default App;
