import React, { useState } from "react";
// @material-ui/core components
import TextField from '@material-ui/core/TextField';
// core components
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";
import Verify from "../../../components/Verify/Verify.js";
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import deLocale from "date-fns/locale/de";
import moment from 'moment-timezone';
//api
import API from '../../../api/api';

const garageApi = new API();

export default function RequestSection(props) {

  const [verify, setVerify] = useState(false);
  const [sent, setSent] = useState(false);
  const [snackBar, setSnackBar] = useState(false);
  const [contact, setContact] = useState({
    name: '',
    email: '',
    message: '',
    phone: '',
    date: null,
    dateTo: null,
    car: 0,
  });

  const [contactError, setContactError] = useState({
    name: false,
    email: false,
    message:false
  });

  function verifyDialog()
  {
    var update = {...contactError};
    if(contact.name === "") {
      update.name = true;
    }
    else if (contact.email === "") {
      update.email = true;
    }
    else if (contact.message === "") {
      update.message = true;
    }
    else
    {
      update.email = false;
      update.name = false;
      update.message = false;
      setVerify(true);
    }
    setContactError(update);
  }

  function closeVerifyDialog(result)
  {
    if(result)
    {
      var sendContact = contact;

      sendContact.car = props.car.id;
      sendContact.date = new Date(moment(contact.date).format('YYYY-MM-DD HH:mm:ss'));;
      sendContact.dateTo = new Date(moment(contact.dateTo).format('YYYY-MM-DD HH:mm:ss'));;

      garageApi.endpoints.carContact.create(sendContact)
        .then((resultGET) => {
          try
          {
            contact.name = "";
            contact.email = "";
            contact.message = "";
            contact.phone = "";
            contact.date = null;
            contact.dateTo = null;
            setSent(true);
          }
          catch(e)
          {
            setSnackBar(true);
          }
        });
    }
    setVerify(false);
  }

  function changeContact(e,key)
  {
    var update = {...contact};
    update[key] = e;
    setContact(update);
  }

  function closeSnackBar (event, reason)
  {
    if (reason === 'clickaway') {
      return;
    }

    setSnackBar(false);
  }

  return (
    <div style={{paddingTop:'0px'}} className='grid-section'>
      <Snackbar open={snackBar} autoHideDuration={6000} onClose={closeSnackBar}>
        <MuiAlert elevation={6} variant="filled" onClose={closeSnackBar} severity="warning" >Server Fehler 500</MuiAlert>
      </Snackbar>
      <Verify open={verify} close={closeVerifyDialog}/>
      <GridContainer justify="center">
        <GridItem cs={12} sm={12} md={8}>
          <h2 style={{fontSize:'25px'}}  className='grid-title'>Anfrage</h2>
          <h4 className='grid-description'>
            Geben Sie unten Ihre Angaben sowie das gewünschte Datum ein.<br></br>
            Wir werden uns dann bei Ihnnen melden. Oder Rufen Sie uns an 079 413 01 02.
          </h4>
          <form>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <TextField
                  label="Name"
                  fullWidth
                  error={contactError.name}
                  style={{
                    marginBottom: '20px',
                  }}
                  value={contact.name}
                  onChange={e => changeContact(e.target.value,'name')}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <TextField
                  label="E-Mail Adresse"
                  fullWidth
                  error={contactError.email}
                  type="email"
                  style={{
                    marginBottom: '20px',
                  }}
                  value={contact.email}
                  onChange={e => changeContact(e.target.value,'email')}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <TextField
                  label="Telefonnummer"
                  fullWidth
                  error={contactError.phone}
                  style={{
                    marginBottom: '20px',
                  }}
                  value={contact.phone}
                  onChange={e => changeContact(e.target.value,'phone')}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <MuiPickersUtilsProvider locale={deLocale}  utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    style={{width:'calc(50% - 20px)', position: 'relative', float:'left'}}
                    disableToolbar
                    variant="inline"
                    format="dd.MM.yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    minDate={new Date()}
                    value={contact.date}
                    onChange={e => changeContact(e,'date')}
                    KeyboardButtonProps={{
                      'aria-label': 'Datum Auswählen',
                    }}
                  />
                  <div className="question-date-select">bis</div>
                  <KeyboardDatePicker
                    style={{width:'calc(50% - 20px)', position: 'relative', float:'left'}}
                    disableToolbar
                    variant="inline"
                    format="dd.MM.yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    minDate={new Date()}
                    value={contact.dateTo}
                    onChange={e => changeContact(e,'dateTo')}
                    KeyboardButtonProps={{
                      'aria-label': 'Datum Auswählen',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <TextField
                  label="Nachricht"
                  multiline
                  error={contactError.message}
                  fullWidth
                  minRows={4}
                  style={{
                    marginTop:'10px',
                    marginBottom: '40px',
                  }}
                  value={contact.message}
                  onChange={e => changeContact(e.target.value,'message')}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                {sent ?
                  <p>Ihre Kontakt Anfrage wurde versandt.</p>
                  :
                  <Button variant="contained" onClick={() => verifyDialog()} color="primary">Senden</Button>
                }
              </GridItem>
            </GridContainer>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}
