import axios from 'axios';

axios.defaults.baseURL = 'https://api.autodec.ch/';

class API {
  constructor()
  {
    this.endpoints = {};
    this.endpoints.cars = this.createBasicCRUDEndpoints({ name: 'cars-external' });
    this.endpoints.formContact = this.createBasicCRUDEndpoints({ name: 'contact-form-external' });
    this.endpoints.carContact = this.createBasicCRUDEndpoints({ name: 'cars-form-external' });
  }

  createBasicCRUDEndpoints = ({ name }) => {

    var authToken = '';

    if (localStorage.getItem('token') !== null)
    {
      authToken = localStorage.getItem('token');
    }

    const endpoints = {};

    const resourceURL = `v1/${name}`;

    endpoints.getAll = (config = { crossDomain: true}) =>  axios.get(`${resourceURL}/`, config).catch(err => console.log('error'));
    endpoints.create = (toCreate, config = { crossDomain: true}) => axios.post(`${resourceURL}/`, toCreate, config).catch(err => this.catchFuntion(err));
    endpoints.getOne = ({ id }, config = { crossDomain: true}) => axios.get(`${resourceURL}/${id}/`, config).catch(err => console.log('error'));
    endpoints.getPage = ({ get }, config = { crossDomain: true, params: get}) =>  axios.get(`${resourceURL}/`, config).catch(err => console.log('error'));
    return endpoints;
  }
}

export default API;
