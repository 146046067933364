import React, { useState, useEffect } from 'react';
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer"
import ParallaxCache from "../../components/Parallax/ParallaxCache";
import { ParallaxBanner  } from 'react-scroll-parallax';
import { useLocation, useParams } from 'react-router-dom'
import CarSection from "./Sections/CarSection";
import RequestSection from "./Sections/RequestSection";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import {Helmet} from "react-helmet";
//api
import API from '../../api/api';

const garageApi = new API();

export default function NewsOnePage(props) {
  const location = useLocation();
  const { configuration } = props;
  const { id } = useParams();
  const [snackBar, setSnackBar] = useState(false);
  const [car, setCar] = useState({});
  const [loadingCar, setLoadingCar] = useState(false);

  useEffect(() => {

    var id = window.location.pathname.split('-')[1].split('.')[0];

    garageApi.endpoints.cars.getOne( { id: id } )
      .then((carGET) => {
        try
        {
          console.log(carGET.data);
          setCar(carGET.data);
          setLoadingCar(true);
        }
        catch(e)
        {
          setSnackBar(true);
        }

      });

   // eslint-disable-next-line
  }, []);

  function closeSnackBar (event, reason)
  {
    if (reason === 'clickaway') {
      return;
    }

    setSnackBar(false);
  }


  return (
    <div>
    <Helmet>
        <title>Stiffler Fahrzeugvermietung - { loadingCar ?  (car.make + ' ' + car.model) : 'Fahrzeuge'}</title>
    </Helmet>
      <Snackbar open={snackBar} autoHideDuration={6000} onClose={closeSnackBar}>
        <MuiAlert elevation={6} variant="filled" onClose={closeSnackBar} severity="warning" >Server Fehler 500</MuiAlert>
      </Snackbar>
      <Header
        color="transparent"
        fixed
        currentLocation={location.pathname}
      />
      <div className="page">
        <div style={{top:'100px', marginBottom: '200px'}} className="page-content">
          <div className="car-page-title">{(car.make + ' ' + car.model)}</div>
          <CarSection car={car} loadingCar={loadingCar}  />
          <RequestSection car={car} />
        </div>
        <Footer footerText={configuration.footerText} partnerText={configuration.partnerText} />
      </div>
    </div>
  );
}
