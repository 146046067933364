import React, { useState, useEffect } from 'react';

// core components
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";
import CarArea from "../../../components/CarArea/CarArea.js";
import Skeleton from '@material-ui/lab/Skeleton';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
//api
import API from '../../../api/api';

const garageApi = new API();

export default function CarSection(props) {

  const [cars, setCars] = useState({});
  const [snackBar, setSnackBar] = useState(false);
  const [loadingCars, setLoadingCars] = useState(false);

  useEffect(() => {

    garageApi.endpoints.cars.getAll()
      .then((carsGET) => {
        try
        {
          setCars(carsGET.data.results);
          setLoadingCars(true);
        }
        catch(e)
        {
          setSnackBar(true);
        }
      });

  }, []);

  function closeSnackBar (event, reason)
  {
    if (reason === 'clickaway') {
      return;
    }

    setSnackBar(false);
  }

  return (
    <>
      <div className="grid-section">
        <Snackbar open={snackBar} autoHideDuration={6000} onClose={closeSnackBar}>
          <MuiAlert elevation={6} variant="filled" onClose={closeSnackBar} severity="warning" >Server Fehler 500</MuiAlert>
        </Snackbar>
        <h2 style={{ marginBottom: '30px' }} className="grid-title">Personenwagen</h2>
        <GridContainer justify="center">

          { loadingCars ?
            <>
              { cars.map(function(car){

                if(car.type == 1)
                {
                  return (
                    <GridItem key={car.unikey} xs={12} sm={4} md={4}>
                      <CarArea
                        key={car.unikey}
                        id={car.id}
                        title={car.make + " " + car.model}
                        description={car.description}
                        image={car.carImage.image['400']}
                        data={car}
                      />
                    </GridItem>
                  )
                }
               })}
             </>
            :
            Array.from({ length: 3 }, (_, i) =>
              <GridItem key={i} xs={12} sm={12} md={4}>
                <CarArea
                  title={<Skeleton variant="text" width={'100%'} />}
                  description=<Skeleton variant="rect" width={'100%'} height={118} />
                  image=<Skeleton variant="circle" width={100} height={100} />
                />
              </GridItem>
            )
          }
        </GridContainer>

        <h2 style={{ marginBottom: '30px', marginTop: '80px'  }} className="grid-title">Lieferwagen</h2>
        <GridContainer justify="center">

          { loadingCars ?
            <>
              { cars.map(function(car){
                if(car.type == 2)
                {
                  return (
                    <GridItem key={car.unikey} xs={12} sm={4} md={4}>
                      <CarArea
                        key={car.unikey}
                        id={car.id}
                        title={car.make + " " + car.model}
                        description={car.description}
                        image={car.carImage.image['400']}
                        data={car}
                      />
                    </GridItem>
                  )
                }
               })}
             </>
            :
            Array.from({ length: 3 }, (_, i) =>
              <GridItem key={i} xs={12} sm={12} md={4}>
                <CarArea
                  title={<Skeleton variant="text" width={'100%'} />}
                  description=<Skeleton variant="rect" width={'100%'} height={118} />
                  image=<Skeleton variant="circle" width={100} height={100} />
                />
              </GridItem>
            )
          }
        </GridContainer>
      </div>

    </>
  );
}
