import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { ParallaxBanner } from 'react-scroll-parallax';
import ParallaxCache from "../../components/Parallax/ParallaxCache";
import TypeWriterEffect from 'react-typewriter-effect';
import { useLocation } from 'react-router-dom'
import CarsSection from "./Sections/CarsSection";
import ContactSection from "./Sections/ContactSection";
import {Helmet} from "react-helmet";
import startImage from '../../assets/images/background.jpg';

export default function HomePage(props) {
  const location = useLocation();
  const { configuration } = props;

  return (
    <div>
      <Helmet>
          <title>Stiffler Fahrzeugvermietung - Startseite</title>
      </Helmet>

      <Header
        color="transparent"
        fixed
        currentLocation={location.pathname}
      />
      <div className="page">
        <ParallaxBanner
            className="open-parralax-container"
            layers={[
                {
                    image: startImage,
                    amount: 0.2,
                    speed: -20,
                }
            ]}
            style={{
                height: 'auto',
            }}
        >
            <div className="open-parralax">
              <div className="open-parralax-content">
                <div className="open-parralax-content-title"><h1>Stiffler Fahrzeugvermietung</h1></div>
                <TypeWriterEffect
                  textStyle={{
                    fontFamily: 'Helvetica',
                    textShadow:' 1px 1px 2px black',
                    color: 'white',
                    textAlign: 'center',
                    fontWeight: 500,
                    marginTop: '10px',
                    fontSize: '25px',
                  }}
                  hideCursorAfterText={true}
                  startDelay={500}
                  cursorColor="white"
                  multiText={["Personenwagen, Lieferwagen"]}
                  multiTextDelay={2500}
                  typeSpeed={30}
                />
              </div>
            </div>
            <ParallaxCache />
          </ParallaxBanner>
        <div className="page-content">
          <div className="mouse-scroll">
            <div className="mouse">
              <div className="mouse-in"></div>
            </div>
            <div>
                <span className="down-arrow-1"></span>
                <span className="down-arrow-2"></span>
                <span className="down-arrow-3"></span>
             </div>
          </div>
          <CarsSection/>
          <ContactSection contactText={"Test"}/>
        </div>
        <Footer footerText={"Test"} partnerText={"test-123"} />
      </div>
    </div>
  );
}
