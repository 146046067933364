import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer"
import ParallaxCache from "../../components/Parallax/ParallaxCache";
import { ParallaxBanner  } from 'react-scroll-parallax';
import { useLocation } from 'react-router-dom';
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import {Helmet} from "react-helmet";

export default function OnlineTermin(props) {
  const location = useLocation();
  const { configuration } = props;

  return (
    <div>
      <Helmet>
          <title>Stiffler Fahrzeugvermietung - Impressum</title>
      </Helmet>
      <Header
        color="transparent"
        fixed
        currentLocation={location.pathname}
      />
      <div className="page">
        <ParallaxBanner
            className="open-parralax-container"
            layers={[
                {
                    image: ( configuration.impressumImage ? configuration.impressumImage.image.header  : ''),
                    amount: 0.5,
                }
            ]}
            style={{
                height: '100%',
            }}
        >
            <div className="open-parralax">
              <div className="open-parralax-content">
                <div className="open-parralax-content-title">Impressum</div>
              </div>
            </div>
            <ParallaxCache />
        </ParallaxBanner>
        <div className="page-content">
          <div className="grid-section">

            <div className="mouse-scroll">
              <div className="mouse">
                <div className="mouse-in"></div>
              </div>
              <div>
                  <span className="down-arrow-1"></span>
                  <span className="down-arrow-2"></span>
                  <span className="down-arrow-3"></span>
               </div>
            </div>
            <GridContainer justify="center">
              <GridItem style={{ marginTop: '30px', textAlign:'left' }} xs={12} sm={12} md={12}>
                <h2 className="grid-title">Impressum</h2>
                <div>
                  <p>Betreiber der Autovermietung Stiffler GmbH</p>
                  <p><br/></p>
                  <ul>
                    <li>Geschäftsführer: Yannick Gutknecht</li>
                    <li>Firmensitz und Head-Office:</li>
                  </ul>
                  <p class="ql-indent-1">Autovermietung Stiffler GMBH</p>
                  <p class="ql-indent-1">Mattastrasse 46</p>
                  <p class="ql-indent-1">7270 Davos Platz</p>
                  <p><br/></p>
                  <ul>
                    <li>Kontakt:</li>
                  </ul>
                  <p class="ql-indent-1">Tel: <a href="tel:+41814132720 " rel="noopener noreferrer" target="_blank">+41 81 413 27 20 </a></p>
                  <p class="ql-indent-1">E-Mail: <a href="mailto:info@stiffler-fahrzeugvermietung.ch " rel="noopener noreferrer" target="_blank">info@stiffler-fahrzeugvermietung.ch </a></p>
                  <p class="ql-indent-1">Webseite: <a href="https://stiffler-fahrzeugvermietung.ch" rel="noopener noreferrer" target="_blank">https://stiffler-fahrzeugvermietung.ch</a></p>
                  <p class="ql-indent-1"><br/></p>
                  <ul>
                    <li>Rechtsform: Gesellschaft mit beschränkter Haftung</li>
                    <li>UID: <a href="https://www.uid.admin.ch/Detail.aspx?uid_id=CHE227312870" rel="noopener noreferrer" target="_blank">CHE-262.120.223&nbsp;</a></li>
                    <li>CH-ID: CH-350-4006795-8</li>
                    <li>EHRA-ID: 1568666</li>
                    <li>Kantonaler Auszug: <a href="https://gr.chregister.ch/cr-portal/auszug/auszug.xhtml?uid=CHE-262.120.223" rel="noopener noreferrer" target="_blank">Web</a></li>
                  </ul>
                  <p><br/></p>
                  <p>Unsere Datenschutzbestimmungen finden Sie <a href="https://stiffler-fahrzeugvermietung.ch/datenschutz" rel="noopener noreferrer" target="_blank">hier</a>.</p>
                  <p>Der Nutzung der im Zuge der Impressumspflicht und der weiteren veröffentlichten Kontaktdaten durch Dritte bspw. zur Übersendung von unangeforderter Werbung und Informationsmaterialien wird widersprochen. Wir behalten uns rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor.</p>
                  <p><br/></p>
                  <h2>Urheberrecht</h2>
                  <p>Der Inhalt und die Darstellung der Website <a href="https://stiffler-fahrzeugvermietung.ch" rel="noopener noreferrer" target="_blank">https://stiffler-fahrzeugvermietung.ch</a> sind urheberrechtlich geschützt und unterliegen dem schweizerischen Urheberrecht. Vervielfältigungen, Verwendung einzelner Texte oder Textteile sowie von Bildmaterial bedarf unserer vorherigen schriftlichen Zustimmung. Die unerlaubte Vervielfältigung oder Weitergabe einzelner Inhalte oder kompletter Seiten ist nicht gestattet und strafbar.</p>
                  <p><br/></p>
                  <h2>Haftung/Disclaimer/Links</h2>
                  <p>Trotz sorgfältiger Kontrolle, ständiger Prüfung und Aktualisierung der Informationen können wir keine Gewähr für die Inhalte der Website übernehmen. Trotz Anwendung größter Sorgfalt können wir nicht ausschließen, dass sich einzelne Daten zwischenzeitlich geändert haben. Eine Haftung wird daher für Aktualität, Vollständigkeit und Richtigkeit der auf der Website dargestellten Informationen nicht übernommen. Die Anwendung unserer Website erfolgt auf eigene Gefahr. Mit der reinen Nutzung unserer Website kommt keinerlei Vertragsverhältnis zwischen dem Nutzer und uns zustande. Wir übernehmen keine Haftung bei technischen Problemen der Website.</p>
                  <p>Diese Website enthält Verknüpfungen zu Websites Dritter ("externe Links"). Diese Websites unterliegen der Haftung der jeweiligen Betreiber. Insbesondere sind wir nicht verantwortlich und haften auch nicht für den Inhalt solcher Websites, die durch Verlinkung erreicht werden. Das Setzen von externen Links bedeutet nicht, dass wir die hinter dem Verweis oder Link liegenden Inhalte zu Eigen machen. Wir bemühen uns Verlinkungen aktuell zu halten.</p>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </div>
        <Footer footerText={configuration.footerText} partnerText={configuration.partnerText} />
      </div>
    </div>
  );
}
