import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer"
import ParallaxCache from "../../components/Parallax/ParallaxCache";
import { ParallaxBanner  } from 'react-scroll-parallax';
import { useLocation } from 'react-router-dom'
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import {Helmet} from "react-helmet";

export default function DataProtection(props) {
  const location = useLocation();
  const { configuration } = props;

  return (
    <div>
      <Helmet>
          <title>Stiffler Fahrzeugvermietung - Datenschutz</title>
      </Helmet>
      <Header
        color="transparent"
        fixed
        currentLocation={location.pathname}
      />
      <div className="page">
        <ParallaxBanner
            className="open-parralax-container"
            layers={[
                {
                    image: ( configuration.dataProtectionImage ? configuration.dataProtectionImage.image.header  : ''),
                    amount: 0.5,
                }
            ]}
            style={{
                height: '100%',
            }}
        >
            <div className="open-parralax">
              <div className="open-parralax-content">
                <div className="open-parralax-content-title">Datenschutz</div>
              </div>
            </div>
            <ParallaxCache />
        </ParallaxBanner>
        <div className="page-content">
          <div className="grid-section">

            <div className="mouse-scroll">
              <div className="mouse">
                <div className="mouse-in"></div>
              </div>
              <div>
                  <span className="down-arrow-1"></span>
                  <span className="down-arrow-2"></span>
                  <span className="down-arrow-3"></span>
               </div>
            </div>
            <GridContainer justify="center">
              <GridItem style={{ marginTop: '30px', textAlign:'left' }} xs={12} sm={12} md={12}>
                <h2 className="grid-title">Datenschutz Erklährung</h2>
                <div>
                  <h3>1. Erhebung, Verarbeitung und Nutzung personenbezogener Daten</h3>
                  <p>Bei einem Besuch dieser Website erheben wir keine personenbezogenen Daten von Ihnen. Personenbezogene Daten werden nur erhoben, wenn Sie uns diese selbst über unser Kontaktformular oder Online-Termin Formular mitteilen. Diese Daten werden ausschliesslich zur Beantwortung Ihres Anliegens genutzt.</p>
                  <p><br/></p>
                  <h3>2. Verwendung von eigenen Cookies</h3>
                  <p>Beim Aufruf bestimmter Seiten wird ein so genanntes «Session-Cookie» gesetzt. Hierbei handelt es sich um eine kleine Textdatei, die nach Ende der Browser-Sitzung automatisch wieder von Ihrem Computer gelöscht wird. Diese Datei dient ausschliesslich dazu, bestimmte Applikationen nutzen zu können. Sie können bei den meisten Webbrowsern die Einstellungen so ändern, dass Ihr Browser neue Cookies nicht akzeptiert oder Sie können erhaltenen Cookies löschen lassen. Wie dies konkret für Ihren Browser funktioniert, kann in der Regel über dessen Hilfe-Funktion in Erfahrung gebracht werden.</p>
                  <p><br/></p>
                  <h3>3.&nbsp;Server-Log-Dateien</h3>
                  <p>Wie bei jeder Verbindung mit einem Webserver protokolliert und speichert der Server bestimmte technische Daten. Zu diesen Daten gehören die IP-Adresse und das Betriebssystem Ihres Geräts, die Daten, die Zugriffszeit, die Art des Browsers sowie die Browser-Anfrage inklusive der Herkunft der Anfrage (Referrer). Dies ist aus technischen Gründen erforderlich, um Ihnen unsere Website zur Verfügung zu stellen. Der Webhosting-Anbieter schützt diese Daten mit technischen und organisatorischen Massnahmen vor unerlaubten Zugriffen und gibt sie nicht an Dritte weiter. Soweit wir dabei personenbezogene Daten verarbeiten, tun wir dies aufgrund unseres Interesses, Ihnen die bestmögliche Nutzererfahrung zu bieten und die Sicherheit und Stabilität unserer Systeme zu gewährleisten.</p>
                  <p><br/></p>
                  <h3>4. Google Analytics</h3>
                  <p>
                  Diese Website nutzt den Dienst „Google Analytics“, welcher von der Google Inc. (1600 Amphitheatre Parkway Mountain View, CA 94043, USA) angeboten wird, zur Analyse der Websitebenutzung durch Nutzer. Der Dienst verwendet „Cookies“ – Textdateien, welche auf Ihrem Endgerät gespeichert werden. Die durch die Cookies gesammelten Informationen werden im Regelfall an einen Google-Server in den USA gesandt und dort gespeichert.

                  Auf dieser Website greift die IP-Anonymisierung. Die IP-Adresse der Nutzer wird innerhalb der Mitgliedsstaaten der EU und des Europäischen Wirtschaftsraum gekürzt. Durch diese Kürzung entfällt der Personenbezug Ihrer IP-Adresse. Im Rahmen der Vereinbarung zur Auftragsdatenvereinbarung, welche die Websitebetreiber mit der Google Inc. geschlossen haben, erstellt diese mithilfe der gesammelten Informationen eine Auswertung der Websitenutzung und der Websiteaktivität und erbringt mit der Internetnutzung verbundene Dienstleistungen.

                  Sie haben die Möglichkeit, die Speicherung des Cookies auf Ihrem Gerät zu verhindern, indem Sie in Ihrem Browser entsprechende Einstellungen vornehmen. Es ist nicht gewährleistet, dass Sie auf alle Funktionen dieser Website ohne Einschränkungen zugreifen können, wenn Ihr Browser keine Cookies zulässt.

                  Weiterhin können Sie durch ein Browser-Plugin verhindern, dass die durch Cookies gesammelten Informationen (inklusive Ihrer IP-Adresse) an die Google Inc. gesendet und von der Google Inc. genutzt werden. Folgender Link führt Sie zu dem entsprechenden Plugin: https://tools.google.com/dlpage/gaoptout?hl=de
                  Hier finden Sie weitere Informationen zur Datennutzung durch die Google Inc.: https://support.google.com/analytics/answer/6004245?hl=de</p>
                  <p><br/></p>
                  <h3>7. Ihre Rechte</h3>
                  <p>Ihnen stehen grundsätzlich die Rechte auf Auskunft, Berichtigung, Löschung, Einschränkung, Datenübertragbarkeit, Widerruf und Widerspruch zu. Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstösst oder Ihre datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt worden sind, können Sie sich bei der Aufsichtsbehörde beschweren.</p>
                  <p>Sie erreichen uns unter folgenden Kontaktdaten:</p>
                  <p><br/></p>
                  <p>Autovermietung Stiffler GMBH</p>
                  <p>Mattastrasse 46</p>
                  <p>7270 Davos Platz</p>
                  <p><a href="tel:+41814132720 " rel="noopener noreferrer" target="_blank">+41 81 413 27 20 </a></p>
                  <p><a href="mailto:info@stiffler-fahrzeugvermietung.ch " rel="noopener noreferrer" target="_blank">info@stiffler-fahrzeugvermietung.ch </a></p>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </div>
        <Footer footerText={configuration.footerText} partnerText={configuration.partnerText} />
      </div>
    </div>
  );
}
