import React from "react";
import GridContainer from "../Grid/GridContainer.js";
import GridItem from "../Grid/GridItem.js";
import { Link as RouterLink } from "react-router-dom";
import Button from '@material-ui/core/Button';

import carSeats from '../../assets/images/car-seats.svg';
import carPS from '../../assets/images/car-ps.svg';
import carFuel from '../../assets/images/car-fuel.svg';
import carShift from '../../assets/images/car-shift.svg';

import truckInnerHeight from '../../assets/images/truck-inner-height.svg';
import truckInnerWidth from '../../assets/images/truck-inner-width.svg';
import truckInnerLength from '../../assets/images/truck-inner-length.svg';
import truckHeight from '../../assets/images/truck-height.svg';

export default function CarArea(props) {
  const { key, title, description, image, id, data } = props;

  console.log(data);

  return (
    <RouterLink key={key} className="info-area-link" to={`/fahrzeuge/${title.toString().replace(/\W/g, '')}-${id}.html`}>
      <div className="info-area">
        <div className="post-area-image-wrapper">
          { (typeof(image) === 'object') ?
            image
            :
            <>
              <img className="post-area-image" src={image} alt={title}/>
              <div className="post-area-image-cover" style={{ backgroundImage: `url(${image})`, }}></div>
            </>
          }
        </div>
        <div className="info-area-description-wrapper">
          <h4 className="info-area-title">{title}</h4>
        </div>
        <div className="car-area-small-specs">
          { (typeof(data) === 'object') ?
            <>
            {
              (data.type == 1) ?
                <GridContainer  justify="center" spacing={2}>
                  <GridItem style={{width:'60px', margin:'10px', height:'80px', padding:'0px'}} xs={12} sm={4} md={4}>
                    <div className="car-spec-box">
                      <img src={carSeats}></img>
                    </div>
                    <div className="car-spec-box-text">{data.seatsAll}</div>
                  </GridItem>
                  <GridItem style={{width:'60px', height:'80px', margin:'10px', padding:'0px'}} xs={12} sm={4} md={4}>
                    <div className="car-spec-box">
                      <img src={carPS}></img>
                    </div>
                    <div className="car-spec-box-text">{data.performance} PS</div>
                  </GridItem>
                  <GridItem style={{width:'60px', height:'80px', margin:'10px', padding:'0px'}} xs={12} sm={4} md={4}>
                    <div className="car-spec-box">
                      <img src={carFuel}></img>
                    </div>
                    <div className="car-spec-box-text">
                    {
                      {
                        1: <>Diesel</>,
                        2: <>Benzin</>,
                        3: <>Elektro</>,
                      }[data.carFuel]
                    }
                    </div>
                  </GridItem>
                  <GridItem style={{width:'60px', height:'80px', margin:'10px', padding:'0px'}} xs={12} sm={4} md={4}>
                    <div className="car-spec-box">
                      <img src={carShift}></img>
                    </div>
                    <div className="car-spec-box-text">
                    {
                      {
                        1: <>Automat</>,
                        2: <>Geschalten</>,
                      }[data.driveType]
                    }
                    </div>
                  </GridItem>
                </GridContainer>
                :
                <GridContainer  justify="center" spacing={2}>
                  <GridItem style={{width:'60px', margin:'10px', height:'80px', padding:'0px'}} xs={12} sm={4} md={4}>
                    <div className="car-spec-box">
                      <img src={truckHeight}></img>
                    </div>
                    <div className="car-spec-box-text">{data.carHeight} m</div>
                  </GridItem>
                  <GridItem style={{width:'60px', height:'80px', margin:'10px', padding:'0px'}} xs={12} sm={4} md={4}>
                    <div className="car-spec-box">
                      <img src={carFuel}></img>
                    </div>
                    <div className="car-spec-box-text">
                    {
                      {
                        1: <>Diesel</>,
                        2: <>Benzin</>,
                        3: <>Elektro</>,
                      }[data.carFuel]
                    }
                    </div>
                  </GridItem>
                  <GridItem style={{width:'60px', height:'80px', margin:'10px', padding:'0px'}} xs={12} sm={4} md={4}>
                    <div className="car-spec-box">
                      <img src={carShift}></img>
                    </div>
                    <div className="car-spec-box-text">
                    {
                      {
                        1: <>Automat</>,
                        2: <>Geschalten</>,
                      }[data.driveType]
                    }
                    </div>
                  </GridItem>
                  <GridItem style={{width:'60px', height:'80px', margin:'10px', padding:'0px'}} xs={12} sm={4} md={4}>
                    <div className="car-spec-box">
                      <img src={truckInnerHeight}></img>
                    </div>
                    <div className="car-spec-box-text">{data.loadRoomHeight} m</div>
                  </GridItem>
                  <GridItem style={{width:'60px', height:'80px', margin:'10px', padding:'0px'}} xs={12} sm={4} md={4}>
                    <div className="car-spec-box">
                      <img src={truckInnerWidth}></img>
                    </div>
                    <div className="car-spec-box-text">{data.loadRoomWidth} m</div>
                  </GridItem>
                  <GridItem style={{width:'60px', height:'80px', margin:'10px', padding:'0px'}} xs={12} sm={4} md={4}>
                    <div className="car-spec-box">
                      <img src={truckInnerLength}></img>
                    </div>
                    <div className="car-spec-box-text">{data.loadRoomLength} m</div>
                  </GridItem>
                </GridContainer>
            }
            </>
            :
            <>
            </>
          }
        </div>
      </div>
    </RouterLink>
  );
};
