import React from "react";
import GridContainer from "../Grid/GridContainer.js";
import GridItem from "../Grid/GridItem.js";
import Logo from '../../assets/images/logo.svg'
import Link from '@material-ui/core/Link'
import { Link as RouterLink } from "react-router-dom";
import facebook from '../../assets/images/facebook.png';
import twitter from '../../assets/images/twitter.png';
import instagram from '../../assets/images/instagram.png';


export default function HeaderLinks(props) {

  return (
    <div className="footer">
      <div style={{ padding: '0px', textAlign: 'left' }} className="grid-section">
        <GridContainer justify="center" style={{ maxWidth: '100%'}}>
          <GridItem xs={12} sm={6} md={3}>
            <div className="footer-title">
              <img className="footer-logo" src={Logo} alt="Logo" />
            </div>
            <div className="footer-links">
              <div className="footer-box">
                <Link  component={RouterLink} to='/impressum' style={{ color:'#14a0db' }}>Impressum</Link>
              </div>
              <div className="footer-box">
                <Link  component={RouterLink} to='/datenschutz' style={{ color:'#14a0db' }}>Datenschutz</Link>
              </div>
            </div>

          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <h5 className="footer-title">Navigation</h5>
            <div className="footer-links">
              <div className="footer-box">
                <Link  component={RouterLink} to='/' style={{ color:'#14a0db' }}>Startseite</Link>
              </div>
              <div className="footer-box">
                <Link  component={RouterLink} to='/fahrzeuge/AudiA4-2.html' style={{ color:'#14a0db' }}>Audi A4</Link>
              </div>
              <div className="footer-box">
                <Link  component={RouterLink} to='/fahrzeuge/VWT6-6.html' style={{ color:'#14a0db' }}>VW T6</Link>
              </div>
              <div className="footer-box">
                <Link  component={RouterLink} to='/fahrzeuge/AudiA6-8.html' style={{ color:'#14a0db' }}>Audi A6</Link>
              </div>
              <div className="footer-box">
                <Link  component={RouterLink} to='/fahrzeuge/VWUP-9.html' style={{ color:'#14a0db' }}>VW UP!</Link>
              </div>
              <div className="footer-box">
                <Link  component={RouterLink} to='/fahrzeuge/VWT5-10.html' style={{ color:'#14a0db' }}>VW T5</Link>
              </div>
              <div className="footer-box">
                <Link  component={RouterLink} to='/fahrzeuge/IvecoDaily-1.html' style={{ color:'#14a0db' }}>Iveco Daily</Link>
              </div>
              <div className="footer-box">
                <Link  component={RouterLink} to='/fahrzeuge/IvecoDaily-3.html' style={{ color:'#14a0db' }}>Iveco Daily</Link>
              </div>
              <div className="footer-box">
                <Link  component={RouterLink} to='/fahrzeuge/VWCrafter-4.html' style={{ color:'#14a0db' }}>VW Crafter</Link>
              </div>
              <div className="footer-box">
                <Link  component={RouterLink} to='/fahrzeuge/MercedesBenzVito-5.html' style={{ color:'#14a0db' }}>Mercedes-Benz Vito</Link>
              </div>
              <div className="footer-box">
                <Link  component={RouterLink} to='/fahrzeuge/VWT5-7.html' style={{ color:'#14a0db' }}>VW T5 Hochdach</Link>
              </div>
            </div>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <h5 className="footer-title">Kontakt</h5>
            <div className="footer-box">
              Autovermietung Stiffler GmbH<br></br>
              Mattastrasse 46<br></br>
              7270 Davos Platz<br></br>
              079 413 01 02<br></br>
            </div>
          </GridItem>
        </GridContainer>
      </div>
      <div className="copyright-header">Autovermietung Stiffler GmbH © {(new Date().getFullYear())}</div>
    </div>
  );
}
